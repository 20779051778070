import "./App.css";
import AnimatedOutlet from "./AnimatedOutlet";

function App() {
  return (
    <div>
      <AnimatedOutlet />
    </div>
  )
}

export default App;
